var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "reports-map-".concat(_vm.mode),
      "load-dictionaries": _vm.loadDictionaries,
      "loaded": _vm.filteringPanel.loaded,
      "show-labels": false,
      "hide-custom-filters": true,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "dict-loaded": _vm.onFilteringPanelLoad,
      "search": _vm.getData,
      "change": _vm.filterData,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('hr'), _c('b-overlay', {
    attrs: {
      "show": this.isLoading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm"
    }
  }, [_vm.dataTable.dataSet.length === 0 ? _c('div', {
    staticClass: "p-3"
  }, [_vm._v(" No data available ")]) : _vm._e(), _c('v-client-table', {
    ref: "dataTable",
    attrs: {
      "name": "client_table_reports_notifications",
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options,
      "columns": _vm.dataTable.columns
    },
    on: {
      "filter": _vm.onFilter
    },
    model: {
      value: _vm.dataTable.dataSet,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTable, "dataSet", $$v);
      },
      expression: "dataTable.dataSet"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }