<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              :dataset-name="`reports-map-${mode}`"
              :load-dictionaries="loadDictionaries"
              :loaded="filteringPanel.loaded"
              :show-labels="false"
              :hide-custom-filters="true"
              :filters="filteringPanel.filters"
              @dict-loaded="onFilteringPanelLoad"
              @search="getData"
              @change="filterData"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <hr />
        <b-overlay
          :show="this.isLoading"
          :opacity="0.5"
          spinner-variant="secondary"
          rounded="sm"
        >
          <div v-if="dataTable.dataSet.length === 0" class="p-3">
            No data available
          </div>

          <v-client-table
            @filter="onFilter"
            ref="dataTable"
            name="client_table_reports_notifications"
            :data="dataTable.dataSet"
            :options="dataTable.options"
            :columns="dataTable.columns"
            v-model="dataTable.dataSet"
          />
        </b-overlay>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

export default {
  name: "Receipts",
  props: {
    mode: {
      type: String,
      default: "all-notifications"
    }
  },
  components: {
    FilteringPanel
  },
  data: function() {
    return {
      isLoading: false,
      rawData: {},
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: "select",
            title: "User Name",
            name: "user_name",
            trackby: "id",
            label: "label",
            options: [],
            dataType: "string",
            selected: {}
          },
          {
            type: "select",
            title: "Category",
            name: "category",
            trackby: "id",
            label: "label",
            options: [],
            dataType: "string",
            selected: {}
          },
          {
            type: "daterange",
            defaultRange: "This quarter",
            title: "Report period",
            dataType: "datetime",
            name: "report-period"
          }
        ]
      },
      dataTable: {
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: true,
          filterable: [
            "ID",
            "Category",
            "Frequency",
            "User Name",
            "Period Start",
            "Period End",
            "Report ID",
            "Submitted At",
            "Notifications",
            "Last Notification"
          ],
          perPage: 50,
          perPageValues: [],
          saveState: true,
          storage: "local"
        },
        columns: [
          "ID",
          "Category",
          "Frequency",
          "User Name",
          "Period Start",
          "Period End",
          "Report ID",
          "Submitted At",
          "Notifications",
          "Last Notification"
        ],
        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      }
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    async loadDictionaries() {
      let self = this;

      const users = async () => {
        self.$api.get("dictionaries/users").then(response => {
          self.filteringPanel.filters.find(
            f => f.name === "user_name"
          ).options = response.map(u => ({
            id: u.id,
            label: u.full_name
          }));
        });
      };

      const categories = () => {
        self.$api.get("dictionaries/reports/categories").then(response => {
          this.filteringPanel.filters.find(
            f => f.name === "category"
          ).options = [
            ...response.map(u => ({
              id: u.id,
              label: u.name
            }))
          ];
        });
      };

      Promise.all([users(), categories()]).then(
        () => (this.filteringPanel.loaded = true)
      );
    },

    onFilteringPanelLoad() {
      this.getData();
    },
    onCustomFilterSelected() {},
    onFilteringPanelStateChange() {},
    filterData: function(e) {
      this.filteringPanel.selected = e;

      if (!this.rawData.length) return;

      let filteredData = this.rawData;

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected["user_name"]
          ? this.filteringPanel.selected["user_name"].label === i["User Name"]
          : true
      );

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected["category"]
          ? this.filteringPanel.selected["category"].label === i["Category"]
          : true
      );

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected["report-period"]
          ? new Date(i["Period Start"]) >=
              new Date(
                this.filteringPanel.selected["report-period"].startDate
              ) &&
            new Date(i["Period End"]) <=
              new Date(this.filteringPanel.selected["report-period"].endDate)
          : true
      );

      this.dataTable.dataSet = filteredData;
    },
    onFilter() {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length;
    },
    getData: async function() {
      this.isLoading = true;

      let url = "users/reports/notifications";

      let response = await this.$api.get(url);

      this.rawData = response;

      this.isLoading = false;

      this.dataTable.dataSet = this.rawData;
    }
  },
  watch: {}
};
</script>

<style></style>
